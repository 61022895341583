import { createAuthGuard } from "@auth0/auth0-vue";
import { App } from "vue";
import { RouteRecordRaw } from "vue-router";

export function createAdminRoutes(app: App): RouteRecordRaw[] {
  const authGuard = createAuthGuard(app);
  return [
    {
      path: "/admin",
      name: "admin",
      component: () => import("../pages/AdminIndex.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Admin",
        layout: "AdminDashboard",
      },
    },

    // Autopilot extractions
    {
      path: "/admin/autopilot-extractions",
      name: "autopilot-extractions",
      component: () => import("../pages/AutopilotExtractions.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Autopilot Extractions",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/autopilot-extractions/create",
      name: "autopilot-extraction-create",
      component: () => import("../pages/AutopilotExtractionCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create Autopilot Extraction",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/autopilot-extractions/edit/:id",
      name: "autopilot-extraction-edit",
      component: () => import("../pages/AutopilotExtractionEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Autopilot Extraction",
        layout: "AdminDashboard",
      },
    },
    // AI Signals
    {
      path: "/admin/ai-signals",
      name: "ai-signals",
      component: () => import("../pages/AISignalsCreated.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "AI Signals",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/ai-signals/create",
      name: "ai-signals-create",
      component: () => import("../pages/AISignalsCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "AI Signals",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/ai-signals/edit/:id",
      name: "ai-signals-edit",
      component: () => import("../pages/AISignalsEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit AI Signals",
        layout: "AdminDashboard",
      },
    },

    //Autopilot Batch
    {
      path: "/admin/autopilot-batch",
      name: "autopilot-batch",
      component: () => import("../pages/BackfillsCreated.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Autopilot Batch",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/autopilot-batch/create",
      name: "autopilot-batch-create",
      component: () => import("../pages/BackfillCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create Autopilot Batch",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/autopilot-batch/view/:id",
      name: "autopilot-batch-view",
      component: () => import("../pages/BackfillView.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Autopilot Batch",
        layout: "AdminDashboard",
      },
    },

    //Retro Pilot
    {
      path: "/admin/retro-pilot",
      name: "retro-pilot",
      component: () => import("../pages/RetroPilotsCreated.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Retropilot",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/retro-pilot/create",
      name: "retro-pilot-create",
      component: () => import("../pages/RetroPilotCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create Retro Pilot",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/retro-pilot/view/:id",
      name: "retro-pilot-view",
      component: () => import("../pages/RetroPilotView.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Retro Pilot",
        layout: "AdminDashboard",
      },
    },

    //AI Playground
    {
      path: "/admin/ai-playground",
      name: "ai-playground",
      component: () => import("../pages/AINotificationsCreated.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "AI Playground",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/ai-playground/create",
      name: "ai-playground-create",
      component: () => import("../pages/AINotificationsCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "AI Workflow Create",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/ai-playground/edit/:id",
      name: "ai-playground-edit",
      component: () => import("../pages/AINotificationsEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "AI Playground Edit",
        layout: "AdminDashboard",
      },
    },
    //AI Call Automation
    {
      path: "/admin/contact-automations",
      name: "contact-automations",
      component: () => import("../pages/ContactAutomationsCreated.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Contact Call Automations",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/contact-automations/create",
      name: "contact-automations-create",
      component: () => import("../pages/ContactAutomationsCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Contact Call Automations Create",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/contact-automations/edit/:id",
      name: "contact-automations-edit",
      component: () => import("../pages/ContactAutomationsEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Contact Automations Edit",
        layout: "AdminDashboard",
      },
    },
    //AI Call Audit
    {
      path: "/admin/call-audit",
      name: "call-audit",
      component: () => import("../pages/AICallAudit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "AI Call Audit",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/briefs",
      name: "briefs",
      component: () => import("../pages/ExecBriefWorkflows.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Executive Brief Workflows",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/briefs/edit/:id",
      name: "briefs-edit",
      component: () => import("../pages/ExecBriefsEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Executive Brief Workflow",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/briefs/create",
      name: "briefs-create",
      component: () => import("../pages/ExecBriefsCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create Executive Brief Workflow",
        layout: "AdminDashboard",
      },
    },
    //scheduled notifications
    {
      path: "/admin/scheduled-notifications",
      name: "scheduled-notifications",
      component: () => import("../pages/ScheduledNotifications.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Scheduled Notifications",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/scheduled-notifications/create",
      name: "scheduled-notification-create",
      component: () => import("../pages/ScheduledNotificationCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create Scheduled Notifications",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/scheduled-notifications/edit/:id",
      name: "scheduled-notification-edit",
      component: () => import("../pages/ScheduledNotificationEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Scheduled Notifications",
        layout: "AdminDashboard",
      },
    },
    //scheduled reports
    {
      path: "/admin/scheduled-reports",
      name: "scheduled-reports",
      component: () => import("../pages/ScheduledReportsCreated.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Scheduled Reports",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/scheduled-reports/create",
      name: "scheduled-reports-create",
      component: () => import("../pages/ScheduledReportsCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create Scheduled Report",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/scheduled-reports/edit/:id",
      name: "scheduled-reports-edit",
      component: () => import("../pages/ScheduledReportsEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Scheduled Report",
        layout: "AdminDashboard",
      },
    },
    //Email Follow-Up Config
    {
      path: "/admin/email-follow-up-config",
      name: "admin-email-follow-up-config",
      component: () => import("../pages/EmailFollowUpHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Email Follow-Up",
        layout: "AdminDashboard",
      },
    },
    // slack app home settings
    {
      path: "/admin/slack-home-screen/configuration",
      name: "slack-home-screen-configuration",
      component: () => import("../pages/settings/SlackHomeSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
      },
    },

    //notification flows
    {
      path: "/admin/notifications/flows",
      name: "flow-notifications",
      component: () => import("../pages/FlowNotifications.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Notification Flows",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/notifications/flows/create",
      name: "flow-notifications-create",
      component: () => import("../pages/FlowNotificationsCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Notification Flows",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/notifications/flows/edit/:id",
      name: "flow-notifications-edit",
      component: () => import("../pages/FlowNotificationsEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Notification Flows",
        layout: "AdminDashboard",
      },
    },

    //ai summary notifications
    {
      path: "/admin/notifications/ai-summary",
      name: "ai-summary-notifications",
      component: () => import("../pages/AISummaryNotifications.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "AI Summary Notifications",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/notifications/ai-summary/create",
      name: "ai-summary-notifications-create",
      component: () => import("../pages/AISummaryNotificationsCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create AI Workflow",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/notifications/ai-summary/edit/:id",
      name: "ai-summary-notifications-edit",
      component: () => import("../pages/AISummaryNotificationsEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit AI Workflow",
        layout: "AdminDashboard",
      },
    },

    //gifs
    {
      path: "/admin/notifications/gif/home",
      name: "gif-home",
      component: () => import("../pages/GifHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Gif Home",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/notifications/gif/edit/:id",
      name: "gif-edit",
      component: () => import("../pages/GifEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Gif",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/notifications/gif/search",
      name: "gif-search",
      component: () => import("../pages/GifSearch.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Search Gifs",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/notifications/gif/add/:id",
      name: "gif-add",
      component: () => import("../pages/GifAdd.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Add Gif",
        layout: "AdminDashboard",
      },
    },

    //workflows
    {
      path: "/admin/workflows",
      name: "workflows-home",
      component: () => import("../pages/WorkflowHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Workflows",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/workflows/edit/:id",
      name: "workflows-edit",
      component: () => import("../pages/WorkflowEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Workflow",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/workflows/create/advanced",
      name: "workflows-create-advanced",
      component: () => import("../pages/WorkflowAdvanced.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create Workflow",
        layout: "AdminDashboard",
      },
    },

    //Analytics Dashboard
    {
      path: "/admin/analytics",
      name: "analytics-home",
      component: () => import("../pages/AnalyticsHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Usage Analytics",
        layout: "AdminDashboard",
      },
    },

    //execution pipeline
    {
      path: "/admin/execution-pipeline",
      name: "pipeline",
      component: () => import("../pages/ExecutionPipeline.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Execution Logs",
        layout: "AdminDashboard",
      },
    },

    {
      path: "/admin/integrations-home",
      name: "integrations-home",
      component: () => import("../pages/IntegrationsHome.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
      },
    },

    {
      path: "/integration-authorize",
      name: "integration-authorize",
      component: () => import("../pages/IntegrationAuthorize.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "UserLogin",
      },
    },

    {
      path: "/admin/team",
      name: "team",
      component: () => import("../pages/TeamHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Team",
        layout: "AdminDashboard",
      },
    },

    {
      path: "/admin/non-editor",
      name: "non-editor",
      component: () => import("../pages/NonEditor.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
      },
    },

    // Settings - Integrations - Zoom
    {
      path: "/admin/settings/integrations/zoom",
      name: "admin-settings-integrations-zoom",
      component: () => import("../pages/SettingsIntegrationsZoom.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Integrations - Zoom",
        layout: "AdminDashboard",
      },
    },

    //coaching settings
    {
      path: "/admin/coaching/settings",
      name: "admin-coaching-settings",
      component: () => import("../pages/CoachingSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Caching Settings",
        layout: "AdminDashboard",
      },
    },

    //dealrooms
    {
      path: "/admin/settings/dealrooms",
      name: "admin-settings-dealrooms",
      component: () => import("../pages/settings/DealRoomSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Deal Rooms",
        layout: "AdminDashboard",
      },
    },

    //rooms
    {
      path: "/admin/settings/rooms/created",
      name: "admin-settings-rooms",
      component: () => import("../pages/settings/RoomsCreated.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Rooms",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/settings/rooms/edit/:id",
      name: "admin-settings-rooms-edit",
      component: () => import("../pages/settings/RoomEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Edit Deal Room Workflow",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/settings/rooms/create",
      name: "admin-settings-rooms-create",
      component: () => import("../pages/settings/RoomCreate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Create Deal Room Workflow",
        layout: "AdminDashboard",
      },
    },

    //account room settings
    {
      path: "/admin/settings/accounts",
      name: "admin-settings-accounts",
      component: () => import("../pages/settings/AccountSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Accounts",
        layout: "AdminDashboard",
      },
    },

    //security
    {
      path: "/admin/settings/security",
      name: "admin-settings-security",
      component: () => import("../pages/settings/SecuritySettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Security",
        layout: "AdminDashboard",
      },
    },

    //playbook
    {
      path: "/admin/settings/playbook",
      name: "admin-settings-playbook",
      component: () => import("../pages/settings/PlaybookHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Playbook",
        layout: "AdminDashboard",
      },
    },

    //salesforce
    {
      path: "/admin/settings/salesforce",
      name: "admin-settings-salesforce",
      component: () => import("../pages/settings/SalesforceSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Salesforce",
        layout: "AdminDashboard",
      },
    },

    //google drive
    {
      path: "/admin/settings/google-drive",
      name: "admin-settings-google-drive",
      component: () => import("../pages/settings/GoogleDriveAccountSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Google Drive",
        layout: "AdminDashboard",
      },
    },

    //zoom
    {
      path: "/admin/settings/zoom",
      name: "admin-settings-zoom",
      component: () => import("../pages/settings/ZoomAccountSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings - Zoom",
        layout: "AdminDashboard",
      },
    },

    //ai settings
    {
      path: "/admin/momentum-ai-settings",
      name: "admin-settings-momentum-ai",
      component: () => import("../pages/settings/MomentumAISettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Momentum AI Settings",
        layout: "AdminDashboard",
      },
    },

    //company info settings
    {
      path: "/admin/company-info",
      name: "admin-company-info",
      component: () => import("../pages/settings/CompanyInfoSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Company Info",
        layout: "AdminDashboard",
      },
    },

    // demo pages
    {
      path: "/admin/demo/icons",
      name: "demo-icons",
      component: () => import("../pages/demo/AppIcons.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Icons",
      },
    },
    {
      path: "/admin/demo/field-paths",
      name: "demo-field-paths",
      component: () => import("../pages/demo/FieldPaths.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Field Paths",
      },
    },
    {
      path: "/admin/demo/condition-builder",
      name: "demo-condition-builder",
      component: () => import("../pages/demo/ConditionBuilder.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Condition Builder",
      },
    },
    {
      path: "/admin/demo/select-field-model",
      name: "demo-select-field-model",
      component: () => import("../pages/demo/SelectFieldModal.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Select Field Modal",
      },
    },
    {
      path: "/admin/demo/cron-scheduler",
      name: "demo-cron-scheduler",
      component: () => import("../pages/demo/CronScheduler.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Cron Scheduler",
      },
    },
    {
      path: "/admin/demo/sql-filter-builder",
      name: "demo-sql-filter-builder",
      component: () => import("../pages/demo/SQLFilterBuilder.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "SQL Filter Builder",
      },
    },

    //component library
    {
      path: "/admin/library/alert",
      name: "library-alert",
      component: () => import("../pages/component-library/CAlertPage.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Alert",
      },
    },
    {
      path: "/admin/library/button",
      name: "library-button",
      component: () => import("../pages/component-library/CButtonPage.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Button",
      },
    },
    {
      path: "/admin/library/checkbox",
      name: "library-checkbox",
      component: () => import("../pages/component-library/CCheckboxPage.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Checkbox",
      },
    },
    {
      path: "/admin/library/draggable",
      name: "library-draggable",
      component: () => import("../pages/component-library/CDraggablePage.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Draggable",
      },
    },
    {
      path: "/admin/library/input",
      name: "library-input",
      component: () => import("../pages/component-library/CInputPage.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Input",
      },
    },
    {
      path: "/admin/library/number-input",
      name: "library-number-input",
      component: () => import("../pages/component-library/CNumberInputPage.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Number Input",
      },
    },
    {
      path: "/admin/library/radio",
      name: "library-radio",
      component: () => import("../pages/component-library/CRadioPage.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Radio",
      },
    },
    {
      path: "/admin/library/select",
      name: "library-select",
      component: () => import("../pages/component-library/CSelectPage.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Select",
      },
    },
    {
      path: "/admin/library/switch",
      name: "library-switch",
      component: () => import("../pages/component-library/CSwitchPage.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Switch",
      },
    },
    {
      path: "/admin/library/table",
      name: "library-table",
      component: () => import("../pages/component-library/CTablePage.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Table",
      },
    },
    {
      path: "/admin/library/tabs",
      name: "library-tabs",
      component: () => import("../pages/component-library/CTabsPage.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Tabs",
      },
    },
    {
      path: "/admin/library/tags",
      name: "library-tags",
      component: () => import("../pages/component-library/CTagsPage.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Tags",
      },
    },
    {
      path: "/admin/library/textarea",
      name: "library-textarea",
      component: () => import("../pages/component-library/CTextareaPage.vue"),
      beforeEnter: authGuard,
      meta: {
        layout: "AdminDashboard",
        title: "Textarea",
      },
    },
    // Admin meetings
    {
      path: "/admin/meetings",
      name: "admin-meetings",
      component: () => import("../pages/MeetingsHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Meetings",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/meeting/:meetingId",
      name: "admin-meeting",
      component: () => import("../pages/MeetingView.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Meeting Details",
        layout: "AdminDashboard",
      },
    },
    {
      path: "/admin/meetings/report",
      name: "admin-meetings-report",
      component: () => import("../pages/MeetingReport.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Meeting Report",
        layout: "AdminDashboard",
      },
    },
  ];
}
