<template>
  <component :is="icon" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
const modules: Record<string, any> = import.meta.glob("../icons/*.vue", { eager: true });
const components = {};
for (const path in modules) {
  if (modules[path]) {
    const module = modules[path];
    const name = /\/([^/]+)\./.exec(path)[1];
    components[name] = module.default;
  }
}

export default defineComponent({
  components,
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    if (!components[props.icon]) {
      throw Error(`Icon ${props.icon} not found`);
    }
  },
});
</script>
