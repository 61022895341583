import store from "../store/index";
import { AccessRoleEnum, SupportRoleEnum, TAuthUser, UserRoleEnum } from "../types/user";

export const canRoleUseAdminPage = (authUser?: TAuthUser): boolean | undefined =>
  isRoleEditor(authUser) || isRoleUserAdmin(authUser);

export const isRoleUser = (authUser?: TAuthUser): boolean | undefined =>
  isRole(authUser, UserRoleEnum.USER) || isRoleEditor(authUser);

export const isRoleEditor = (authUser?: TAuthUser): boolean | undefined =>
  isRole(authUser, UserRoleEnum.EDITOR) || isRoleOrganizationAdmin(authUser);

export const isRoleUserAdmin = (authUser?: TAuthUser): boolean | undefined =>
  isRole(authUser, UserRoleEnum.USER_ADMIN) || isRoleOrganizationAdmin(authUser);

export const isRoleStrictlyUserAdmin = (authUser?: TAuthUser): boolean | undefined =>
  isRole(authUser, UserRoleEnum.USER_ADMIN);

export const isRoleOrganizationAdmin = (authUser?: TAuthUser): boolean | undefined =>
  isRole(authUser, UserRoleEnum.ORGANIZATION_ADMIN);

export const isRoleMomentumSupport = (authUser?: TAuthUser): boolean | undefined =>
  isRole(authUser, SupportRoleEnum.MOMENTUM_SUPPORT);

function isRole(givenAuthUser: TAuthUser, role: AccessRoleEnum): boolean | undefined {
  const authUser = givenAuthUser ?? store.state.authUser;
  if (!authUser) {
    // Return undefined when the authUser is not yet initialized inside store
    return undefined;
  }
  return authUser.role === role;
}
