import gql from "graphql-tag";

export const queryGetOrganization = gql`
  query GetOrganization($organizationId: Int!) {
    organization(where: { id: { _eq: $organizationId } }) {
      deleted_at
      id
      name
      shouldDeleteAt
    }
  }
`;
