import gql from "graphql-tag";
import { DocumentNode } from "graphql";
import {
  IOrganizationAdminSettings,
  TOrganization,
  TOrganizationSettings,
  OrganizationPlanEnum,
} from "../types/organization";
import { BaseApi } from "./base";

export class OrganizationApi extends BaseApi {
  async getById(id: number): Promise<TOrganization> {
    type TVariables = { id: number };
    type TData = { organization_by_pk: TOrganization };

    const query: DocumentNode = gql`
      query GetOrganizationByPK($id: Int!) {
        organization_by_pk(id: $id) {
          name
          plan
          shouldDeleteAt
          enabled
          organizationSettings: organization_settings {
            id
            adminSettings
            emailGenerated
            meetingReport
            settings
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { id });
      return result.organization_by_pk;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async updateFeatureFlagForSupport(variables: {
    organizationId: number;
    flag: string;
    value: boolean;
  }): Promise<boolean> {
    type TVariables = { organizationId: number; value: boolean };
    type TData = { momentum: { organization: { updateFeatures: { accepted: boolean } } } };
    const query: DocumentNode = gql`
      mutation UpdateOrganizationFeatureFlagForSupport($organizationId: Int!, $value: Boolean!) {
        momentum {
          organization {
            updateFeatures(input: { ${variables.flag}: $value }, organizationId: $organizationId) {
              accepted
            }
          }
        }
      }
    `;
    try {
      const result: TData = await this.mutate<TData, TVariables>(query, {
        organizationId: variables.organizationId,
        value: variables.value,
      });
      return result.momentum.organization.updateFeatures.accepted;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async updateName(name: string, id: number): Promise<TOrganization> {
    type TVariables = { name: string; id: number };
    type TData = { update_organization_by_pk: TOrganization };
    const query: DocumentNode = gql`
      mutation UpdateOrganizationName($id: Int!, $name: String = "") {
        update_organization_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
          enabled
          name
          shouldDeleteAt
          plan
        }
      }
    `;

    try {
      const result: TData = await this.mutate<TData, TVariables>(query, { name, id });
      this.postSuccess("Successfully updated organization name");
      return result.update_organization_by_pk;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async updatePlan(plan: OrganizationPlanEnum, id: number): Promise<TOrganization> {
    type TVariables = { plan: OrganizationPlanEnum; id: number };
    type TData = { update_organization_by_pk: TOrganization };

    const query: DocumentNode = gql`
      mutation UpdateOrganizationPlan($id: Int!, $plan: String = "") {
        update_organization_by_pk(pk_columns: { id: $id }, _set: { plan: $plan }) {
          plan
        }
      }
    `;

    try {
      const result: TData = await this.mutate<TData, TVariables>(query, { plan, id });
      this.postSuccess("Successfully updated organization plan");
      return result.update_organization_by_pk;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async updateEnabled(enabled: boolean, id: number): Promise<TOrganization> {
    type TVariables = { enabled: boolean; id: number };
    type TData = { update_organization_by_pk: TOrganization };

    const query: DocumentNode = gql`
      mutation UpdateOrganizationEnabled($id: Int!, $enabled: Boolean = false) {
        update_organization_by_pk(pk_columns: { id: $id }, _set: { enabled: $enabled }) {
          enabled
        }
      }
    `;

    try {
      const result: TData = await this.mutate<TData, TVariables>(query, { enabled, id });
      this.postSuccess("Successfully updated organization enabled");
      return result.update_organization_by_pk;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async updateShouldDeleteAt(shouldDeleteAt: string, id: number): Promise<TOrganization> {
    type TVariables = { shouldDeleteAt: string; id: number };
    type TData = { update_organization_by_pk: TOrganization };

    const query: DocumentNode = gql`
      mutation UpdateOrganizationShouldDelete($id: Int!, $shouldDeleteAt: timestamptz) {
        update_organization_by_pk(pk_columns: { id: $id }, _set: { shouldDeleteAt: $shouldDeleteAt }) {
          shouldDeleteAt
        }
      }
    `;

    try {
      const result: TData = await this.mutate<TData, TVariables>(query, { shouldDeleteAt, id });
      this.postSuccess("Successfully updated organization should delete at");
      return result.update_organization_by_pk;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async updateAdminSettings(settings: Partial<IOrganizationAdminSettings>): Promise<IOrganizationAdminSettings> {
    type TVariables = { updatedSettings: Partial<IOrganizationAdminSettings> };
    type TData = { momentum: { organization: { updateAdminSettings: { adminSettings: IOrganizationAdminSettings } } } };

    const query: DocumentNode = gql`
      mutation UpdateOrganizationAdminSettings($updatedSettings: TGqlUpdateOrganizationAdminSettingsInput!) {
        momentum {
          organization {
            updateAdminSettings(input: $updatedSettings) {
              adminSettings
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.mutate<TData, TVariables>(query, {
        updatedSettings: settings,
      });
      this.postSuccess("Successfully updated organization authentication settings");
      return result?.momentum?.organization?.updateAdminSettings?.adminSettings;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async updateAdminSettingsForSupport(
    organizationId: number,
    settings: Partial<IOrganizationAdminSettings>
  ): Promise<IOrganizationAdminSettings> {
    type TVariables = { organizationId: number; updatedSettings: Partial<IOrganizationAdminSettings> };
    type TData = {
      support: { organization: { updateAdminSettings: { adminSettings: IOrganizationAdminSettings } } };
    };

    const query: DocumentNode = gql`
      mutation UpdateOrganizationAdminSettingsForSupport(
        $organizationId: Float!
        $updatedSettings: TGqlUpdateOrganizationAdminSettingsInput!
      ) {
        support {
          organization {
            updateAdminSettings(input: $updatedSettings, organizationId: $organizationId) {
              adminSettings
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.mutate<TData, TVariables>(query, {
        organizationId,
        updatedSettings: settings,
      });
      await this.postSuccess("Successfully updated organization authentication settings");
      return result.support.organization.updateAdminSettings.adminSettings;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async get(): Promise<TOrganization> {
    type TData = { organization: TOrganization };
    const query: DocumentNode = gql`
      query OrganizationSettings {
        organization {
          id
          name
          organizationSettings: organization_settings {
            id
            settings
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, null>(query);
      return result.organization[0];
    } catch (error) {
      await this.handleError(error);
    }
  }

  async updateSettings(updatedSettings: { [key: string]: any }): Promise<TOrganizationSettings> {
    type TVariables = { updatedSettings: { [key: string]: any } };
    type TData = { momentum: { organization: { updateSettings: { settings: TOrganizationSettings } } } };

    const query: DocumentNode = gql`
      mutation UpdateOrganizationSettings($updatedSettings: TGqlUpdateOrganizationSettingsInput!) {
        momentum {
          organization {
            updateSettings(input: $updatedSettings) {
              settings
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.mutate<TData, TVariables>(query, { updatedSettings });
      this.postSuccess("Successfully updated provisioned seats");
      return result.momentum.organization.updateSettings.settings;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getAdminSettings(): Promise<IOrganizationAdminSettings> {
    type TData = { momentum: { organization: { getAdminSettings: { adminSettings: IOrganizationAdminSettings } } } };
    const query: DocumentNode = gql`
      query GetOrganizationAdminSettings {
        momentum {
          organization {
            getAdminSettings {
              adminSettings
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, null>(query);
      return result?.momentum?.organization?.getAdminSettings?.adminSettings;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getAdminSettingsForSupport(organizationId: number): Promise<IOrganizationAdminSettings> {
    type TData = {
      support: { organization: { getAdminSettings: { adminSettings: IOrganizationAdminSettings } } };
    };
    const query: DocumentNode = gql`
      query GetOrganizationAdminSettingsForSupport($organizationId: Float!) {
        support {
          organization {
            getAdminSettings(organizationId: $organizationId) {
              adminSettings
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, { organizationId: number }>(query, { organizationId });
      return result.support.organization.getAdminSettings.adminSettings;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async updateSettingsForSupport(updatedSettings: { [key: string]: any }, organizationId: number): Promise<any> {
    type TVariables = { updatedSettings: { [key: string]: any }; organizationId: number };
    type TData = { support: { organization: { updateSettings: { settings: TOrganizationSettings } } } };
    const query: DocumentNode = gql`
      mutation UpdateOrganizationSettingsForSupport(
        $updatedSettings: TGqlUpdateOrganizationSettingsInput!
        $organizationId: Float!
      ) {
        support {
          organization {
            updateSettings(input: $updatedSettings, organizationId: $organizationId) {
              settings
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.mutate<TData, TVariables>(query, { updatedSettings, organizationId });
      await this.postSuccess("Successfully updated organization settings");
      return result.support.organization.updateSettings.settings;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getAiLicenseConstraints(organizationId: number): Promise<TOrganization> {
    type TVariables = { organizationId: number };
    type TData = { organization: TOrganization };
    const query: DocumentNode = gql`
      query GetOrganizationAiLicenseConstraints($organizationId: Int!) {
        organization(where: { id: { _eq: $organizationId } }) {
          id
          userLicenseConstraints
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { organizationId });
      return result.organization[0];
    } catch (error) {
      await this.handleError(error);
    }
  }
}
