import { TConfigNames } from "./types";
export function renderButtonStyle(configName: TConfigNames, options?: { disabled: boolean }): string {
  switch (configName) {
    case "primaryButton":
      if (options?.disabled) {
        return "bg-blue-300 text-white cursor-not-allowed";
      }
      return "bg-blue-600 text-white hover:opacity-95";
    case "primaryGreenButton":
      if (options?.disabled) {
        return "bg-green-300 text-white cursor-not-allowed";
      }
      return "bg-green-600 text-white hover:opacity-95";
    case "primaryDeleteButton":
      return "bg-red-600 text-white hover:opacity-95";
    case "secondaryButton":
      return "bg-gray-100 hover:bg-gray-200 text-gray-800";
    case "secondaryBlueButton":
      return "bg-blue-100 text-blue-800 hover:opacity-95";
    case "secondaryBluePlainButton":
      return "hover:bg-blue-100 text-blue-800";
    case "secondaryGreenPlainButton":
      return "hover:bg-green-100 text-green-800";
    case "secondaryRoundedButton":
      if (options?.disabled) {
        return "bg-gray-100 text-gray-800 cursor-not-allowed rounded-full";
      }
      return "bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-full";
    case "secondaryRedButton":
      return "bg-red-100 text-red-700 hover:opacity-95";
    case "secondaryRedPlainButton":
      return "hover:bg-red-100 text-red-700";
    case "textBlueButton":
      return "text-blue-600 hover:opacity-95";
    case "textGreenButton":
      return "text-green-600 hover:opacity-95";
    case "textPurpleButton":
      return "text-purple-600 hover:opacity-95";
    case "textPlainButton":
      return "text-gray-900 hover:opacity-95";
    case "textRedButton":
      return "text-red-600 hover:opacity-95";
  }
}

export function renderLoaderStyle(configName: TConfigNames): "white" | "black" {
  if (["secondaryRedButton", "secondaryBlueButton", "textBlueButton"].includes(configName)) {
    return "black";
  }
  return "white";
}
