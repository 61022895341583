import { v4 as uuidv4 } from "uuid";

import { TNotification } from "../types/notification";

export const notifications = {
  state: (): { notifications: TNotification[] } => ({
    notifications: [],
  }),

  getters: {
    getNotifications(state: { notifications: TNotification[] }): TNotification[] {
      return state.notifications;
    },
  },

  mutations: {
    addNotification(state, message: string): void {
      state.notifications.push(message);
    },
    removeNotification(state, id: string): void {
      state.notifications = state.notifications.filter((notification: TNotification) => notification.id !== id);
    },
  },

  actions: {
    setSuccessMessage({ commit }, message: string): void {
      const notification: TNotification = {
        id: uuidv4(),
        message,
        severity: "info",
      };
      commit("addNotification", notification);
    },
    setErrorMessage({ commit }, message: string): void {
      const notification: TNotification = {
        id: uuidv4(),
        message,
        severity: "error",
      };
      commit("addNotification", notification);
    },
    setWarningMessage({ commit }, message: string): void {
      const notification: TNotification = {
        id: uuidv4(),
        message,
        severity: "warning",
      };
      commit("addNotification", notification);
    },
    removeNotification({ commit }, id: string): void {
      commit("removeNotification", id);
    },
  },
};
