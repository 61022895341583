import { isSupportConsole } from "./is-support-console";

export function getViteConnection(): string | undefined {
  return _getViteConnection(_getViteConnectionEnvValue());
}

function _getViteConnectionEnvValue(): string | undefined {
  if (isSupportConsole()) {
    // backwards compatible, so all engineers do not need to add env vars immediately
    return import.meta.env.VITE_CONNECTION_SUPPORT || import.meta.env.VITE_CONNECTION;
  } else {
    return import.meta.env.VITE_CONNECTION;
  }
}

function _getViteConnection(viteConnection: string | undefined | null): string | undefined {
  if ([undefined, null, "ANY"].includes(viteConnection)) {
    return undefined;
  }
  return viteConnection;
}
