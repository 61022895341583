import { createRouter, createWebHistory, Router, RouteRecordRaw } from "vue-router";
import { App } from "vue";
import { createAuthGuard } from "@auth0/auth0-vue";
import { createAdminRoutes } from "./admin";
import { createMainAppRoutes } from "./main-app";
import { createSupportRoutes } from "../support-console/router";
import { isSupportConsole } from "../utils/is-support-console";
import { canRoleUseAdminPage, isRoleEditor, isRoleOrganizationAdmin, isRoleUserAdmin } from "../utils/roles";
import { PageNotFoundError } from "../types/router";

export function getRouters(app: App): { router: Router; routes: RouteRecordRaw[] } {
  const authGuard = createAuthGuard(app);

  const routes: RouteRecordRaw[] = [
    {
      path: "/",
      name: "base",
      redirect: () => {
        if (isSupportConsole()) {
          return "/momentum-support";
        }
        return "/admin";
      },
    },
    // auth
    {
      path: "/auth/callback",
      name: "auth-callback",
      component: () => import("../pages/AuthCallback.vue"),
    },
    {
      path: "/password-login",
      name: "password-login",
      component: () => import("../pages/PasswordLogin.vue"),
    },
    ...createMainAppRoutes(app),
    ...createAdminRoutes(app),
    ...createSupportRoutes(app),
    // integration authentication success page
    {
      path: "/integration/authentication/success",
      name: "integration-authentication-success",
      component: () => import("../pages/IntegrationAuthenticationSuccess.vue"),
    },
    // integration authentication failure page
    {
      path: "/integration/authentication/failure",
      name: "integration-authentication-failure",
      component: () => import("../pages/IntegrationAuthenticationFailure.vue"),
    },
    // deal assist webhook success page
    {
      path: "/webhook/deal-assist/success",
      name: "dealassist-webhook-success",
      component: () => import("../pages/DealAssistWebhookSuccess.vue"),
    },
    {
      path: "/momentum-support/fake-meeting",
      name: "momentum-support-fake-meeting",
      component: () => import("../support-console/pages/MomentumSupportFakeMeeting.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Fake Meeting",
        layout: "AppLayoutDefault",
      },
    },
    //zendesk integration support
    {
      path: "/integration-zendesk-subdomain-input",
      name: "integration-zendesk-subdomain-input",
      component: () => import("../pages/IntegrationZendeskSubdomainInput.vue"),
      meta: {
        layout: "AppLayoutDefault",
      },
    },
    //non auth routes
    {
      path: "/sign-in",
      name: "UserLogin",
      component: () => import("../pages/SignIn.vue"),
      meta: {
        title: "Login",
        layout: "UserLogin",
      },
    },
    {
      path: "/configuration-error",
      name: "configuration-error",
      component: () => import("../pages/ConfigurationError.vue"),
      meta: {
        title: "Error",
        layout: "AppLayoutDefault",
      },
    },
    //share meeting
    {
      path: "/share/meeting/:meetingId",
      name: "share-meeting",
      component: () => import("../pages/MeetingShare.vue"),
      meta: {
        title: "Meeting",
        layout: "AppLayoutDefault",
      },
    },
    //this always needs to be last to catch any invalid paths
    //if route doesn't match from anything above serve a Page Not Found
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: () => import("../pages/PageNotFound.vue"),
      meta: {
        layout: "AppLayoutDefault",
      },
    },
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  router.onError(() => {
    router.push({ name: "not-found" });
  });

  router.beforeResolve((to, _from, next) => {
    if (to.name === "not-found") {
      next();
    } else if (to.name === "admin") {
      // default route based on role
      // user-admin -> team
      // otherwise -> ai-summary-notifications
      if (isRoleUserAdmin() && !isRoleOrganizationAdmin()) {
        next({ name: "team" });
      } else {
        next({ name: "ai-summary-notifications" });
      }
    } else if (to.path.startsWith("/admin/")) {
      if (!canRoleUseAdminPage()) {
        // send non-admins to app
        next({ name: "meetings" });
      } else if (
        // Allow all admins to access the Component Demo and Library pages
        // as long as the app is in local mode
        !to.path.startsWith("/admin/demo") &&
        !to.path.startsWith("/admin/library") &&
        // Allow Organization Admins to access any page
        !isRoleOrganizationAdmin() &&
        // Don't allow Editors to access the Team page
        // Only allow User Admins to access the Team page
        ((isRoleEditor() && to.name === "team") || (isRoleUserAdmin() && to.name !== "team"))
      ) {
        next(new PageNotFoundError());
      } else {
        next();
      }
    } else if (isRoleUserAdmin() && !isRoleOrganizationAdmin()) {
      next({ name: "team" });
    } else {
      next();
    }
  });

  router.beforeEach((to, _from, next) => {
    const id = to.params?.id ?? "";
    const title = to.meta?.title as string | undefined;
    const pageName = (title ?? to.name ?? "")
      .toString()
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
    document.title = `Momentum - ${pageName}${id ? " - " + id : ""}`.trim();
    next();
  });

  router.afterEach(() => {
    if (window.analytics) {
      window.analytics.page();
    }
  });

  return { router, routes };
}
