import gql from "graphql-tag";

export const salesforceObjectsMetadata = gql`
  query SalesforceObjectsMetadata($objectNames: [String!]!, $refresh: Boolean!) {
    momentum {
      salesforce {
        objectMetadata(objectNames: $objectNames, refresh: $refresh) {
          name
          custom
          functionality
          label
          labelPlural
          fields {
            caseSensitive
            custom
            defaultValue
            functionality
            label
            maxLength
            name
            nameField
            objectName
            picklistValues
            readonly
            referenceTo
            relationshipName
            type
            omitFromQuery
          }
        }
      }
    }
  }
`;
