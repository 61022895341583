<template>
  <div class="hidden wizard-xl:flex wizard-xl:flex-shrink-0">
    <div class="flex flex-col w-64">
      <!-- Sidebar component -->
      <div class="flex flex-col h-0 flex-1">
        <div class="flex items-center justify-between h-16 flex-shrink-0 px-6 bg-mm-colors-sidebarBackground">
          <img class="h-6" src="../../assets/images/full-logo-dark.png" alt="Momentum logo">
          <NPopover trigger="hover">
            <template #trigger>
              <button type="button" class="-mr-6" @click="toggleNav">
                <MIcon icon="IconPanelLeftContract" class="w-6 h-6" />
              </button>
            </template>
            <span>Collapse Sidebar</span>
          </NPopover>
        </div>
        <div class="flex-1 flex flex-col overflow-y-auto">
          <!-- Insert navigation links here -->
          <slot />
        </div>

        <div class="space-y-1 pl-4 py-5 bg-mm-colors-sidebarBackground border-t border-gray-200">
          <div class="ml-2">
            <Profile />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { NPopover } from "naive-ui";
import Profile from "./ProfileDropdown.vue";
import MIcon from "../../components/reusable-components/MIcon.vue";

export default defineComponent({
  components: {
    Profile,
    NPopover,
    MIcon,
  },
  setup() {
    const store: any = useStore();

    function toggleNav() {
      store.dispatch("toggleSideBar", !store.state.showSideBar);
    }
    return {
      store,
      toggleNav,
    };
  },
});
</script>
