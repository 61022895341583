import gql from "graphql-tag";
import { DocumentNode } from "graphql";
import { BaseApi } from "../base";
import { TFeatureValue, TFeatureSet } from "./types";

export class FeatureSetApi extends BaseApi {
  async getFeatureNames(): Promise<string[]> {
    type TData = { __type: { fields: { name: string }[] } };

    const query = gql`
      query GetOrganizationFeatureNames {
        __type(name: "organization_feature_set") {
          fields {
            name
          }
        }
      }
    `;

    try {
      const fields: TData = await this.query(query, {}, { fetchPolicy: "cache-first" });
      return fields.__type.fields.map((field) => field.name).filter((name) => name !== "organizationId");
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getAll(): Promise<TFeatureSet> {
    type TVariables = {};
    type TData = { organization_feature_set: TFeatureSet[] };

    try {
      const featureNames = await this.getFeatureNames();
      const query: DocumentNode = gql`
        query GetOrganizationFeatureSet {
          organization_feature_set {
            ${featureNames.join("\n")}
          }
        }
      `;

      const result: TData = await this.query<TData, TVariables>(query, {}, { fetchPolicy: "cache-first" });
      return result.organization_feature_set[0];
    } catch (error) {
      await this.handleError(error);
    }
  }

  async get<T extends TFeatureValue>(feature: string): Promise<T> {
    try {
      const features = await this.getAll();
      return features[feature] as T;
    } catch (error) {
      await this.handleError(error);
    }
  }
}
