import { ApolloProvider } from "@vue/apollo-option";
import { useStore } from "vuex";
import { organizationIntegrationIsAuthenticatedQuery } from "../graphql/integration-info";
import { IntegrationServiceEnum } from "../types/integrations";

export function isOrganizationIntegrationAuthenticated(
  apollo: ApolloProvider,
  service: IntegrationServiceEnum
): Promise<boolean> {
  const store = useStore();
  let isAuthenticated = store.getters["integrations/isOrganizationIntegrationAuthenticated"](service);
  if (isAuthenticated !== undefined) {
    return isAuthenticated;
  }
  return apollo.defaultClient
    .query({
      query: organizationIntegrationIsAuthenticatedQuery,
      variables: {
        service,
      },
    })
    .then((response) => {
      isAuthenticated = Boolean(response.data?.user_integration?.[0]);
      store.dispatch("integrations/setIsOrganizationIntegrationAuthenticated", {
        isOrganizationIntegratedWithService: isAuthenticated,
        service,
      });
      return isAuthenticated;
    })
    .catch((error: Error) => {
      console.error(error.message);
      return false;
    });
}
