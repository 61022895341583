<template>
  <div class="w-full" :class="{'pb-20': authUser?.isImpersonation || state.bannerInfo.length || !state.isAuthenticatedWithSalesforce}">
    <div v-show="state.bannerInfo.length" class="w-full bg-blue-900 text-white text-center flex items-center p-5">
      <div>
        <MIcon icon="IconWarning" class="w-6 h-6 text-yellow-400" />
      </div>
      <p class="ml-3" v-text="state.bannerInfo[0]?.text" />
    </div>
    <div v-show="authUser?.isImpersonation" class="w-full flex items-center px-8 text-sm font-bold p-4 text-white" :class="[renderSupportBannerColor()]">
      <div>
        <MIcon icon="IconWarning" class="w-6 h-6 text-yellow-400 mr-2" />
      </div>
      <p>
        Heads up! You're impersonating <span v-text="profile?.name" /> (<span
          v-text="profile?.email"
        />) from <span v-text="profile?.organizationName" /> with the role
        <span v-text="userRoleNames[authUser?.role]" />. Please be careful and make sure
        <span v-text="profile?.name" /> has agreed to you doing this!
      </p>
    </div>
    <div v-if="shouldShowSalesforceAuthBanner" class="p-4 w-full flex items-center bg-main-app-darkBluePrimary">
      <div>
        <MIcon icon="IconWarning" class="w-6 h-6 text-yellow-500 mr-2" />
      </div>
      <p class="text-white">
        Heads up! You're not authenticated with Salesforce. Follow this link here to authenticate.
        <a class="px-2 py-1 bg-yellow-500 text-black rounded-md cursor-pointer" :href="salesforceAuthUrl" target="_blank" rel="noopener">Authorize Salesforce</a>
      </p>
    </div>

    <slot />
  </div>
</template>

<script lang="ts">
import { ApolloProvider } from "@vue/apollo-option";
import { defineComponent, onMounted, reactive, inject, computed } from "vue";
import { useStore } from "vuex";
import { userRoleNames } from "../../types/user";
import { salesforceAuthUrl } from "../../utils/import-env-vars";
import { isRoleOrganizationAdmin, isRoleUserAdmin } from "../../utils/roles";
import { ServiceStatusEnum } from "../../types/integrations";
import { UserApi } from "../../api/user";
import { isLocalEnvironment } from "../../utils/common-js";

import gql from "graphql-tag";
import MIcon from "../reusable-components/MIcon.vue";

export default defineComponent({
  components: {
    MIcon,
  },
  setup() {
    interface State {
      showUserDetails: boolean;
      showNotification: boolean;
      bannerInfo: any[];
      isAuthenticatedWithSalesforce: boolean;
    }
    const isSupportConsole = inject("isSupportConsole");
    const apollo: ApolloProvider = inject<ApolloProvider>("apollo");
    const userApi: UserApi = new UserApi(apollo);
    const store: any = useStore();
    const state: State = reactive({
      showUserDetails: false,
      showNotification: false,
      bannerInfo: [],
      isAuthenticatedWithSalesforce: true, // default to true to avoid showing the warning banner on page load/refresh
    });

    onMounted(() => {
      getBannerInfo();
      getUserAuthInformation();
    });

    const authUser = computed(() => {
      return store.state.authUser;
    });

    const profile = computed(() => {
      return store.state.profile;
    });

    const shouldShowSalesforceAuthBanner = computed(() => {
      const isRoleUserAdminStrict = isRoleUserAdmin() && !isRoleOrganizationAdmin();
      return !state.isAuthenticatedWithSalesforce && !isSupportConsole && !isRoleUserAdminStrict;
    });

    function getBannerInfo(): Promise<void> {
      return apollo.clients.graphcms
        .query({
          query: gql`
            query BannerInfo {
              banners {
                text
              }
            }
          `,
        })
        .then((result) => {
          if (result.data) {
            state.bannerInfo = result.data.banners;
          }
        });
    }

    async function getUserAuthInformation(): Promise<void> {
      const result = await userApi.getSalesforceIntegrationStatus(store.state.authUser.userId);
      state.isAuthenticatedWithSalesforce = result === ServiceStatusEnum.AUTHENTICATED;
    }

    function renderSupportBannerColor(): string {
      return isLocalEnvironment() ? "bg-green-500" : "bg-red-400";
    }

    return {
      authUser,
      getBannerInfo,
      profile,
      renderSupportBannerColor,
      salesforceAuthUrl,
      shouldShowSalesforceAuthBanner,
      state,
      userRoleNames,
    };
  },
});
</script>

<style scoped>
.warning-bell {
  position: absolute;
  display: block;
  top: 4px;
  right: 6px;
}

.notification-dropdown {
  width: 400px;
}

@media only screen and (max-width: 550px) {
  .notification-dropdown {
    position: fixed;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    width: 95%;
  }
}
</style>
