<template>
  <div class="min-h-full">
    <nav class="border-b border-gray-200 bg-white">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex h-16 justify-between">
          <div class="flex">
            <div class="flex flex-shrink-0 items-center">
              <MIcon icon="IconMomentumLogo" class="h-10 w-auto" />
            </div>
            <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
              <router-link :to="{ name: 'momentum-support-home'}" class="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium text-gray-900" :class="[renderActiveLink('momentum-support-home')]">
                Organizations
              </router-link>
              <router-link :to="{ name: 'momentum-support-entity-search'}" class="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium" :class="[renderActiveLink('momentum-support-entity-search')]">
                Entity Search
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="py-10">
      <header>
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900" v-text="store.state.header" />
        </div>
      </header>
      <main>
        <div class="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
          <router-view />
        </div>
      </main>
    </div>

    <PopUpNotifications />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore, Store } from "vuex";
import { useRoute, RouteLocationNormalizedLoaded } from "vue-router";

import MIcon from "../../components/reusable-components/MIcon.vue";
import PopUpNotifications from "../../components/notifications/PopUpNotifications.vue";
export default defineComponent({
  components: {
    MIcon,
    PopUpNotifications,
  },
  setup() {
    const store: Store<any> = useStore();
    const route: RouteLocationNormalizedLoaded = useRoute();

    function renderActiveLink(routeName: string): string {
      return routeName === route.name
        ? "border-green-500 text-gray-900"
        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700";
    }
    return {
      store,
      renderActiveLink,
    };
  },
});
</script>
