import { TEmailFollowUpEmailSuggestion } from "../types/email-follow-up";
import { TMeeting } from "../types/meeting";
interface State {
  showCreateEmail: boolean;
  emailSuggestion: {
    all: TEmailFollowUpEmailSuggestion[];
    "expiring-soon": TEmailFollowUpEmailSuggestion[];
    expired: TEmailFollowUpEmailSuggestion[];
  };
  meeting: {
    [key: number]: TMeeting;
  };
}
export const emailFollowUp = {
  state: () =>
    <State>{
      showCreateEmail: false,
      emailSuggestion: null,
      meeting: null,
    },

  mutations: {
    setShowCreateEmail(state, data): void {
      state.showCreateEmail = data;
    },
    setEmailSuggestion(state, data): void {
      state.emailSuggestion = data;
    },
    setMeeting(state, data): void {
      state.meeting = data;
    },
  },

  actions: {
    setShowCreateEmail({ commit }, data): void {
      commit("setShowCreateEmail", data);
    },
    setEmailSuggestion({ commit }, data): void {
      commit("setEmailSuggestion", data);
    },
    setMeeting({ commit }, data): void {
      commit("setMeeting", data);
    },
  },
};
