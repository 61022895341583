import { createAuthGuard } from "@auth0/auth0-vue";
import { App } from "vue";
import { RouteRecordRaw } from "vue-router";

export function createMainAppRoutes(app: App): RouteRecordRaw[] {
  const authGuard = createAuthGuard(app);

  return [
    {
      path: "/meetings",
      name: "meetings",
      component: () => import("../pages/home-dashboard/MeetingsView.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Meetings",
        layout: "UserDashboard",
      },
    },
    {
      path: "/meeting/:id",
      name: "meeting",
      component: () => import("../pages/home-dashboard/MeetingEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Meetings",
        layout: "UserDashboard",
      },
    },
    {
      path: "/autopilot",
      name: "autopilot",
      component: () => import("../pages/home-dashboard/AutoPilot.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Autopilot",
        layout: "UserDashboard",
      },
    },
    {
      path: "/email-follow-up",
      name: "email-follow-up",
      component: () => import("../pages/home-dashboard/EmailFollowUpView.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Email Follow-Up",
        layout: "UserDashboard",
      },
    },
    {
      path: "/email-follow-up/config",
      name: "user-email-follow-up-config",
      component: () => import("../pages/home-dashboard/EmailFollowUpUserConfig.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Email Follow-Up Config",
        layout: "UserDashboard",
      },
    },
    {
      path: "/zoom-settings",
      name: "zoom-settings",
      component: () => import("../pages/home-dashboard/ZoomUserSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings Zoom",
        layout: "UserDashboard",
      },
    },
    {
      path: "/pipeline",
      name: "home-pipeline",
      component: () => import("../pages/home-dashboard/PipelineHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Pipeline",
        layout: "UserDashboard",
      },
    },
    {
      path: "/individual-account-connect",
      name: "individual-account-connect",
      component: () => import("../pages/MyIntegrations.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Individual Account Connect",
        layout: "UserDashboard",
      },
    },
  ];
}
