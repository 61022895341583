<template>
  <div class="relative" :class="[renderDisplayFlex(displayFlex, showBadge)]">
    <slot />
    <p v-if="showBadge" class="rounded-full bg-green-100 px-2.5 py-0.5 font-medium text-green-800" :class="[renderTextSize(size), renderAbsoluteStyling(displayFlex)]">
      Beta
    </p>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import { TTextSize } from "./types";

defineProps({
  showBadge: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String as PropType<TTextSize>,
    default: "small",
  },
  displayFlex: {
    type: Boolean,
    default: false,
  },
});

function renderTextSize(size: TTextSize): string {
  switch (size) {
    case "small":
      return "text-xs";
    case "medium":
      return "text-sm";
    case "large":
      return "text-lg";
  }
}

function renderAbsoluteStyling(displayFlex: boolean): string {
  return !displayFlex ? "absolute -top-2 -right-2" : "";
}

function renderDisplayFlex(displayFlex: boolean, showBadge: boolean): string {
  if (!showBadge) return "";
  return displayFlex ? "flex items-center justify-between w-full" : "";
}
</script>
