export const templates = {
  state: () => ({
    templates: [],
    template: null,
    tags: [],
    //anytime this number increases it triggers validation functions in the rendered form components
    validate: 0,
    formComponentErrors: [],
  }),

  mutations: {
    setTemplates(state, data) {
      state.templates = data;
    },
    setTemplate(state, data) {
      state.template = data;
    },
    setTags(state, data) {
      state.tags = data;
    },
    validate(state) {
      state.validate += 1;
    },
    setInvalidError(state, componentType) {
      if (!state.formComponentErrors.some((value) => value === componentType)) {
        const errors = [...state.formComponentErrors, componentType];
        state.formComponentErrors = errors;
      }
    },
    removeInvalidError(state, componentType) {
      const index = state.formComponentErrors.findIndex((value) => value === componentType);
      state.formComponentErrors.splice(index, 1);
    },
    clearErrors(state) {
      state.formComponentErrors = [];
    },
  },

  actions: {
    setTemplates({ commit }, data) {
      commit("setTemplates", data);
    },
    setTemplate({ commit }, data) {
      commit("setTemplate", data);
    },
    setTags({ commit }, data) {
      commit("setTags", data);
    },
    setInvalidError({ commit }, componentType) {
      commit("setInvalidError", componentType);
    },
    removeInvalidError({ commit }, componentType) {
      commit("removeInvalidError", componentType);
    },
    checkValidations({ commit }) {
      commit("validate");
    },
    clearErrors({ commit }) {
      commit("clearErrors");
    },
  },
};
