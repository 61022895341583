import { ApolloClient } from "@apollo/client/core";
import {
  IntegrationFieldTypeEnum,
  IntegrationServiceEnum,
  TIntegrationObjectMetadata,
  TIntegrationObjectName,
  TIntegrationsObjectsMetadata,
} from "../types/integrations";
import { salesforceObjectsMetadata } from "../graphql/salesforce/object-metadata";

export async function fetchObjectsMetadata(
  apolloClient: ApolloClient<unknown>,
  service: IntegrationServiceEnum,
  objectNames: string[],
  options?: { refresh?: boolean }
): Promise<TIntegrationObjectMetadata[]> {
  const refresh = options?.refresh ?? false;
  let objectsMetadata;
  if (service === IntegrationServiceEnum.SALESFORCE) {
    const localObjectNames = objectNames.filter((name) => defaultObjects[service]?.[name]);
    const remoteObjectNames = objectNames.filter((name) => !defaultObjects[service]?.[name]);
    const localObjects = getLocalObjectsMetadata(service, localObjectNames);
    const remoteObjects = await fetchSalesforceObjectsMetadata(apolloClient, remoteObjectNames, { refresh });
    objectsMetadata = localObjects.concat(remoteObjects);
  } else {
    objectsMetadata = getLocalObjectsMetadata(service, objectNames);
  }
  return Promise.resolve(objectsMetadata);
}

function getLocalObjectsMetadata(service: IntegrationServiceEnum, objectNames: string[]): TIntegrationObjectMetadata[] {
  return objectNames.map((objectName) => defaultObjects[service]?.[objectName]);
}

async function fetchSalesforceObjectsMetadata(
  apolloClient: ApolloClient<unknown>,
  objectNames: TIntegrationObjectName[],
  options?: { refresh?: boolean }
): Promise<TIntegrationObjectMetadata[]> {
  const refresh = options?.refresh ?? false;
  if (!objectNames.length) {
    return Promise.resolve([]);
  }
  if (!apolloClient) {
    console.error("fetchSalesforceObjectsMetadata: apolloClient is not initialized");
    throw new Error(`fetchSalesforceObjectsMetadata: apolloClient is not initialized`);
  }
  const result = await apolloClient.query({
    query: salesforceObjectsMetadata,
    variables: {
      objectNames,
      refresh,
    },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });
  let objectMetadatas = (result.data?.momentum?.salesforce?.objectMetadata as TIntegrationObjectMetadata[]) ?? [];
  return objectMetadatas.map((objectMetadata) => ({
    ...objectMetadata,
    fields: [
      ...objectMetadata.fields,
      {
        // This isn't an actual Salesforce field, but will be made available by OneGraph on Salesforce objects
        caseSensitive: false,
        custom: false,
        defaultValue: null,
        functionality: {
          createable: false,
          updateable: false,
        },
        label: "Object URLs",
        maxLength: 0,
        name: "sobjectMetadata",
        nameField: false,
        picklistValues: [],
        readonly: true,
        referenceTo: ["sobjectMetadata" as TIntegrationObjectName],
        relationshipName: "sobjectMetadata",
        type: IntegrationFieldTypeEnum.reference,
      },
    ],
  }));
}

// TODO: Serve everything all this from the backend
const defaultObjects: Partial<TIntegrationsObjectsMetadata> = {
  GONG: {
    Call: {
      name: "Call",
      label: "Call",
      labelPlural: "Calls",
      fields: [
        { label: "URL", name: "url", type: IntegrationFieldTypeEnum.url },
        { label: "Meeting URL", name: "meetingUrl", type: IntegrationFieldTypeEnum.url },
        {
          label: "Direction",
          name: "direction",
          type: IntegrationFieldTypeEnum.picklist,
          picklistValues: [
            { value: "CONFERENCE", label: "Conference" },
            { value: "INBOUND", label: "Inbound" },
            { value: "OUTBOUND", label: "Outbound" },
            { value: "UNKNOWN", label: "Unknown" },
          ],
        },
        { label: "Duration", name: "duration", type: IntegrationFieldTypeEnum.int },
        { label: "ID", name: "id", type: IntegrationFieldTypeEnum.id },
        { label: "Account ID", name: "accountId", type: IntegrationFieldTypeEnum.id },
        { label: "Opportunity ID", name: "opportunityId", type: IntegrationFieldTypeEnum.id },
        { label: "Parties", name: "parties", type: IntegrationFieldTypeEnum.unsupported },
        {
          label: "Content",
          name: "content",
          relationshipName: "content",
          referenceTo: ["Content"],
          type: IntegrationFieldTypeEnum.reference,
        },
        { label: "System", name: "system", type: IntegrationFieldTypeEnum.string },
        { label: "Title", name: "title", type: IntegrationFieldTypeEnum.string },
        { label: "Teammates", name: "teammates", array: true, type: IntegrationFieldTypeEnum.email },
        { label: "External Attendees", name: "externalAttendees", array: true, type: IntegrationFieldTypeEnum.email },
        {
          label: "Primary User ID",
          name: "primaryUserId",
          relationshipName: "primaryUser",
          referenceTo: ["User"],
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
    },
    Content: {
      name: "Content",
      label: "Content",
      labelPlural: "Contents",
      fields: [
        {
          label: "Topics",
          name: "topics",
          array: true,
          relationshipName: "Topics",
          referenceTo: ["Topic"],
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          label: "Structure",
          name: "structure",
          array: true,
          relationshipName: "Structure",
          referenceTo: ["Structure"],
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
    },
    Structure: {
      name: "Structure",
      label: "Structure",
      labelPlural: "Structures",
      fields: [
        { label: "Name", name: "name", type: IntegrationFieldTypeEnum.string },
        { label: "Duration", name: "duration", type: IntegrationFieldTypeEnum.int },
      ],
    },
    Topic: {
      name: "Topic",
      label: "Topic",
      labelPlural: "Topics",
      fields: [
        { label: "Name", name: "name", type: IntegrationFieldTypeEnum.string },
        { label: "Duration", name: "duration", type: IntegrationFieldTypeEnum.int },
      ],
    },
    User: {
      name: "User",
      label: "User",
      labelPlural: "Users",
      fields: [
        { name: "id", label: "ID" },
        { name: "emailAddress", label: "Email Address" },
        { name: "name", label: "Name" },
      ],
    },
  },
  GOOGLE_CALENDAR: {
    Attendee: {
      name: "Attendee",
      label: "Attendee",
      labelPlural: "Attendees",
      fields: [
        { name: "displayName", label: "Display Name" },
        { name: "email", label: "Email" },
      ],
    },
    Event: {
      name: "Event",
      label: "Event",
      labelPlural: "Events",
      fields: [
        { name: "id", label: "ID" },
        { name: "htmlLink", label: "HTML Link" },
        {
          name: "creator",
          label: "Creator",
          relationshipName: "Creator",
          referenceTo: ["Attendee"],
          type: IntegrationFieldTypeEnum.reference,
        },
        { name: "text", label: "Text" },
        {
          name: "end",
          label: "End Time",
          relationshipName: "end",
          referenceTo: ["Time"],
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          name: "Attendees",
          label: "Attendees",
          relationshipName: "Attendees",
          referenceTo: ["Attendee"],
          array: true,
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          name: "start",
          label: "Start Time",
          relationshipName: "start",
          referenceTo: ["Time"],
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
    },
    ExpandedEventInfo: {
      name: "ExpandedEventInfo",
      label: "Expanded Event Info",
      labelPlural: "ExpandedEventInfos",
      fields: [
        { name: "attendeesWithLinkedIn", label: "Attendees With LinkedIn" },
        { name: "teamUserProfiles", label: "Team User Profiles" },
      ],
    },
    Time: {
      name: "Time",
      label: "Time",
      labelPlural: "Times",
      fields: [
        { name: "dateTime", label: "Date Time" },
        { name: "timestamp", label: "Timestamp" },
      ],
    },
  },
  MOMENTUM: {
    Action: {
      name: "Action",
      label: "Action",
      labelPlural: "Actions",
      fields: [
        {
          name: "creatorId",
          label: "Creator ID",
          relationshipName: "Creator",
          referenceTo: ["User"],
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
    },
    Activity: {
      name: "Activity",
      label: "Activity",
      labelPlural: "Activities",
      fields: [
        { name: "createdAt", label: "Created At" },
        {
          name: "creator",
          relationshipName: "creator",
          referenceTo: ["User"],
          type: IntegrationFieldTypeEnum.reference,
          label: "Created By",
        },
        { name: "type", label: "Type" },
      ],
    },
    Assist: {
      name: "Assist",
      label: "Assist",
      labelPlural: "Assists",
      fields: [
        { name: "Name", label: "Name" },
        {
          name: "creatorId",
          label: "Creator ID",
          relationshipName: "Creator",
          referenceTo: ["User"],
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
    },
    AssistChannel: {
      name: "AssistChannel",
      label: "Assist Channel",
      labelPlural: "Assist Channels",
      fields: [
        { name: "channelId", label: "Channel ID" },
        { name: "headerMessageTs", label: "Header Message Timestamp" },
        { name: "sourceType", label: "Source Type" },
        { name: "sourceValue", label: "Source Value" },
      ],
    },
    Call: {
      name: "Call",
      label: "Call",
      labelPlural: "Calls",
      fields: [
        // {
        //   name: "id",
        //   label: "ID",
        //   type: IntegrationFieldTypeEnum.id,
        // },
        {
          name: "sourceType",
          label: "Source",
          type: IntegrationFieldTypeEnum.picklist,
          picklistValues: [
            { label: "Gong", value: "GONG" },
            { label: "Zoom", value: "ZOOM" },
            { label: "Salesloft", value: "SALESLOFT" },
          ],
        },
        {
          name: "suggestedActions",
          label: "Suggested Actions",
          type: IntegrationFieldTypeEnum.string,
          array: true,
        },
        {
          name: "suggestedSummary",
          label: "Suggested Summary",
          type: IntegrationFieldTypeEnum.string,
        },
        // {
        //   name: "url",
        //   label: "URL",
        //   type: IntegrationFieldTypeEnum.url,
        // },
        // {
        //   name: "createdAt",
        //   label: "Created At",
        //   type: IntegrationFieldTypeEnum.datetime,
        // },
      ],
    },
    ChannelPostedMessage: {
      name: "ChannelPostedMessage",
      label: "Channel Posted Message",
      labelPlural: "Channel Posted Messages",
      fields: [
        { name: "slackChannelId", label: "Slack Channel ID" },
        { name: "channelType", label: "Channel Type" },
        { name: "ts", label: "Timestamp" },
        { name: "slackUserId", label: "Slack User ID" },
        { name: "message", label: "Message" },
      ],
    },
    CronScheduler: {
      name: "CronScheduler",
      label: "Cron Scheduler",
      labelPlural: "Custom Schedulers",
      fields: [{ name: "cron", label: "Cron" }],
    },
    // Hosting under MOMENTUM, since CustomObject isn't a real Salesforce object
    CustomObject: {
      name: "CustomObject",
      label: "Custom Object",
      labelPlural: "Custom Objects",
      fields: [
        { name: "id", label: "ID" },
        {
          name: "sobjectMetadata",
          relationshipName: "sobjectMetadata",
          referenceTo: ["sobjectMetadata"],
          type: IntegrationFieldTypeEnum.reference,
          label: "Salesforce Object Metadata",
        },
      ],
    },
    Dealroom: {
      name: "Dealroom",
      label: "Deal Room",
      labelPlural: "Deal Rooms",
      fields: [
        { name: "available", label: "Available" },
        { name: "accountId", label: "Account ID" },
        { name: "id", label: "Deal Room ID" },
        { name: "opportunityId", label: "Opportunity ID" },
        { name: "slackChannelId", label: "Slack Channel ID" },
        { name: "stageName", label: "Stage Name" },
        {
          name: "ownerId",
          relationshipName: "Owner",
          referenceTo: ["User"],
          type: IntegrationFieldTypeEnum.reference,
          label: "Owner ID",
        },
      ],
    },
    Meeting: {
      name: "Meeting",
      label: "Meeting",
      labelPlural: "Meetings",
      fields: [
        { name: "id", label: "Id", type: IntegrationFieldTypeEnum.id },
        {
          name: "id",
          label: "Call",
          type: IntegrationFieldTypeEnum.reference,
          referenceTo: ["Call"],
          relationshipName: "call",
        },
        { name: "endTime", label: "End Time", type: IntegrationFieldTypeEnum.datetime },
        {
          name: "hostUserId",
          label: "Host User ID",
          type: IntegrationFieldTypeEnum.reference,
          referenceTo: ["User"],
          relationshipName: "hostUser",
          limitSelectableToOnlyObjectType: true,
        },
        {
          name: "isExternal",
          label: "Is External?",
          type: IntegrationFieldTypeEnum.boolean,
        },
        {
          name: "momentumOwnerId",
          label: "Momentum Owner ID",
          type: IntegrationFieldTypeEnum.reference,
          referenceTo: ["User"],
          relationshipName: "momentumOwner",
        },
        //same type of error as Call ID
        // {
        //   name: "salesforceAccountId",
        //   label: "Salesforce Account ID",
        //   type: IntegrationFieldTypeEnum.reference,
        //   referenceTo: ["SalesforceAccount"],
        //   relationshipName: "salesforceAccount",
        // },
        //same type of error as Call ID
        // {
        //   name: "salesforceLeadId",
        //   label: "Salesforce Lead ID",
        //   type: IntegrationFieldTypeEnum.reference,
        //   referenceTo: ["SalesforceLead"],
        //   relationshipName: "salesforceOpportunity",
        // },

        //same type of error as Call ID
        // {
        //   name: "salesforceOpportunityId",
        //   label: "Salesforce Opportunity ID",
        //   type: IntegrationFieldTypeEnum.reference,
        //   referenceTo: ["SalesforceOpportunity"],
        //   relationshipName: "salesforceOpportunity",
        // },
        //same type of error as Call ID
        // {
        //   name: "salesforceOwnerId",
        //   label: "Salesforce Owner ID",
        //   type: IntegrationFieldTypeEnum.reference,
        //   referenceTo: ["SalesforceUser"],
        //   relationshipName: "salesforceOwner",
        // },
        { name: "slackNoteChannel", label: "Slack Note Channel", type: IntegrationFieldTypeEnum.string },
        { name: "slackNoteTs", label: "Slack Note Timestamp", type: IntegrationFieldTypeEnum.string },
        { name: "slackNoteUrl", label: "Slack Note URL", type: IntegrationFieldTypeEnum.url },
        { name: "startTime", label: "Start Time", type: IntegrationFieldTypeEnum.datetime },
        { name: "title", label: "Title", type: IntegrationFieldTypeEnum.string },
        { name: "url", label: "URL", type: IntegrationFieldTypeEnum.url },
      ],
    },
    MeetingNote: {
      name: "MeetingNote",
      label: "Meeting Note",
      labelPlural: "Meeting Notes",
      fields: [
        { name: "id", label: "Meeting Note ID" },
        { name: "meetingId", label: "Meeting ID" },
        { name: "createdAt", label: "Created At" },
        { name: "completedAt", label: "Completed At" },
        { name: "title", label: "Title" },
        { name: "content", label: "Content" },
        { name: "sourceType", label: "Source Type" },
        { name: "sourceValue", label: "Source Value" },
        { name: "salesforceNoteId", label: "Salesforce Note ID" },
        { name: "lastSyncedAt", label: "Last Synced At" },
      ],
    },
    FiscalPeriods: {
      name: "FiscalPeriods",
      label: "FiscalPriods",
      labelPlural: "FiscalPeriods",
      fields: [
        {
          name: "Quarters",
          label: "Quarters",
          relationshipName: "Quarters",
          referenceTo: ["Quarters"],
          array: true,
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          name: "Months",
          label: "Months",
          relationshipName: "Months",
          referenceTo: ["Months"],
          array: true,
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
    },
    Period: {
      name: "Period",
      label: "Periods",
      labelPlural: "Periods",
      fields: [
        {
          name: "endDate",
          label: "endDate",
          type: IntegrationFieldTypeEnum.datetime,
        },
        {
          name: "number",
          label: "number",
          type: IntegrationFieldTypeEnum.int,
        },
        {
          name: "startDate",
          label: "startDate",
          type: IntegrationFieldTypeEnum.datetime,
        },
      ],
    },
    Quarters: {
      name: "Quarters",
      label: "Quarters",
      labelPlural: "Quarters",
      fields: [
        {
          name: "current",
          label: "current",
          relationshipName: "Period",
          referenceTo: ["Period"],
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          name: "list",
          label: "list",
          array: true,
          relationshipName: "Period",
          referenceTo: ["Period"],
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          name: "next",
          label: "next",
          relationshipName: "Period",
          referenceTo: ["Period"],
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          name: "previous",
          label: "previous",
          relationshipName: "Period",
          referenceTo: ["Period"],
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
    },
    Months: {
      name: "Months",
      label: "Months",
      labelPlural: "Months",
      fields: [
        {
          name: "current",
          label: "current",
          relationshipName: "Period",
          referenceTo: ["Period"],
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          name: "list",
          label: "list",
          array: true,
          relationshipName: "Period",
          referenceTo: ["Period"],
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          name: "next",
          label: "next",
          relationshipName: "Period",
          referenceTo: ["Period"],
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          name: "previous",
          label: "previous",
          relationshipName: "Period",
          referenceTo: ["Period"],
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
    },
    NoteActionItem: {
      name: "NoteActionItem",
      label: "Note Action Item",
      labelPlural: "Note Action Items",
      fields: [
        { name: "id", label: "Note Action Item ID" },
        { name: "meetingId", label: "Meeting ID" },
        { name: "createdAt", label: "Created At" },
        { name: "completedAt", label: "Completed At" },
        { name: "title", label: "Title" },
        { name: "description", label: "Description" },
        { name: "isCompleted", label: "Is Completed" },
        {
          name: "assignedUser",
          relationshipName: "assignedUser",
          referenceTo: ["User"],
          type: IntegrationFieldTypeEnum.reference,
          label: "Assigned User",
        },
      ],
    },
    Organization: {
      name: "Organization",
      label: "Organization",
      labelPlural: "Organizations",
      fields: [
        { name: "id", label: "Organization ID" },
        { name: "name", label: "Name" },
        {
          name: "slackWorkspaceId",
          relationshipName: "slackWorkspace",
          referenceTo: ["SlackWorkspace"],
          type: IntegrationFieldTypeEnum.reference,
          label: "Slack Workspace ID",
        },
      ],
    },
    Reaction: {
      name: "Reaction",
      label: "Reaction",
      labelPlural: "Reactions",
      fields: [
        {
          name: "creator",
          relationshipName: "creator",
          referenceTo: ["User"],
          type: IntegrationFieldTypeEnum.reference,
          label: "Created",
        },
        { name: "message", label: "Message" },
        { name: "files", label: "Files" },
        { name: "slackChannelId", label: "Slack Channel ID" },
        { name: "emoji", label: "Emoji" },
        { name: "messageAuthor", label: "Message Author" },
        { name: "messageTs", label: "Message Timestamp" },
        { name: "metadata", label: "Metadata" },
      ],
    },
    // Since we can't support cross-service objects, duplicate Salesforce objects required by MOMENTUM here
    SalesforceAccount: {
      name: "SalesforceAccount",
      label: "Salesforce Account",
      labelPlural: "Salesforce Accounts",
      fields: [
        { name: "Id", label: "ID", type: IntegrationFieldTypeEnum.string },
        {
          name: "Name",
          label: "Name",
          type: IntegrationFieldTypeEnum.string,
        },
        {
          name: "OwnerId",
          label: "Owner ID",
          type: IntegrationFieldTypeEnum.reference,
          relationshipName: "Owner",
          referenceTo: ["SalesforceUser"],
        },
      ],
    },
    SalesforceLead: {
      name: "SalesforceLead",
      label: "Salesforce Lead",
      labelPlural: "Salesforce Leads",
      fields: [
        { name: "Id", label: "ID", type: IntegrationFieldTypeEnum.id },
        {
          name: "Name",
          label: "Name",
          type: IntegrationFieldTypeEnum.string,
        },
        {
          name: "Email",
          label: "Email",
          type: IntegrationFieldTypeEnum.email,
        },
        {
          name: "OwnerId",
          label: "Owner ID",
          type: IntegrationFieldTypeEnum.reference,
          relationshipName: "Owner",
          referenceTo: ["SalesforceUser"],
        },
      ],
    },
    SalesforceOpportunity: {
      name: "SalesforceOpportunity",
      label: "Salesforce Opportunity",
      labelPlural: "Salesforce Opportunities",
      fields: [
        { name: "Id", label: "ID", type: IntegrationFieldTypeEnum.id },
        {
          name: "Name",
          label: "Name",
          type: IntegrationFieldTypeEnum.string,
        },
        {
          name: "AccountId",
          label: "Account ID",
          type: IntegrationFieldTypeEnum.reference,
          relationshipName: "Account",
          referenceTo: ["SalesforceAccount"],
        },
        {
          name: "OwnerId",
          label: "Owner ID",
          type: IntegrationFieldTypeEnum.reference,
          relationshipName: "Owner",
          referenceTo: ["SalesforceUser"],
        },
      ],
    },
    SalesforceUser: {
      name: "SalesforceUser",
      label: "Salesforce User",
      labelPlural: "Salesforce Users",
      fields: [
        { name: "Id", label: "ID", type: IntegrationFieldTypeEnum.id },
        {
          name: "Name",
          label: "Name",
          type: IntegrationFieldTypeEnum.string,
        },
        {
          name: "Email",
          label: "Email",
          type: IntegrationFieldTypeEnum.email,
        },
      ],
    },
    SlackAction: {
      name: "SlackAction",
      label: "Slack Action",
      labelPlural: "Slack Actions",
      fields: [
        { name: "type", label: "Type" },
        { name: "triggerId", label: "Trigger ID" },
        { name: "payload", label: "Payload" },
      ],
    },
    SlackFile: {
      name: "SlackFile",
      label: "Slack File",
      labelPlural: "Slack Files",
      fields: [
        { name: "created", label: "Created" },
        { name: "fileType", label: "File Type" },
        { name: "id", label: "Slack File ID" },
        { name: "link", label: "Link" },
        { name: "name", label: "Name" },
      ],
    },
    SlackFileUpload: {
      name: "SlackFileUpload",
      label: "Slack File Upload",
      labelPlural: "Slack File Uploads",
      fields: [
        {
          name: "creator",
          relationshipName: "creator",
          referenceTo: ["User"],
          type: IntegrationFieldTypeEnum.reference,
          label: "Creator",
        },
        { name: "messageTs", label: "Message Timestamp" },
        {
          name: "file",
          relationshipName: "file",
          referenceTo: ["SlackFile"],
          type: IntegrationFieldTypeEnum.reference,
          label: "File",
        },
        { name: "slackChannelId", label: "Slack Channel ID" },
      ],
    },
    SlackReceiver: {
      name: "SlackReceiver",
      label: "Slack Receiver",
      labelPlural: "Slack Receivers",
      fields: [
        { name: "ref", label: "Slack Receiver Reference" },
        { name: "slackChannelId", label: "Slack Channel ID" },
        { name: "slackUserId", label: "Slack User ID" },
        { name: "ts", label: "Timestamp" },
        { name: "type", label: "Type" },
      ],
    },
    SlackWorkspace: {
      name: "SlackWorkspace",
      label: "Slack Workspace",
      labelPlural: "Slack Workspaces",
      fields: [{ name: "teamID", binding: "teamID", label: "Team ID" }],
    },
    // Duplicating under MOMENTUM, since CustomObject isn't a real Salesforce object and requires it
    sobjectMetadata: {
      name: "sobjectMetadata",
      label: "Salesforce Object URLs",
      labelPlural: "Salesforce Objects URLs",
      fields: [
        { name: "uiDetailUrl", label: "UI Detail URL" },
        { name: "uiEditUrl", label: "UI Edit URL" },
      ],
    },
    User: {
      name: "User",
      label: "User",
      labelPlural: "Users",
      fields: [
        { name: "name", label: "Name" },
        { name: "email", label: "Email Address", type: IntegrationFieldTypeEnum.email },
        { name: "imageUrl", label: "Image URL" },
        { name: "slackUserId", label: "Slack User ID", type: IntegrationFieldTypeEnum.id },
        { name: "salesforceUserId", label: "Salesforce User ID" },
      ],
    },
  },
  SALESFORCE: {
    sobjectMetadata: {
      name: "sobjectMetadata",
      label: "Salesforce Object Metadata",
      labelPlural: "Salesforce Objects Metadata",
      fields: [
        {
          array: false,
          caseSensitive: false,
          custom: false,
          defaultValue: null,
          functionality: {
            createable: false,
            updateable: false,
          },
          label: "Detail URL",
          maxLength: 0,
          name: "uiDetailUrl",
          nameField: false,
          picklistValues: [],
          readonly: true,
          referenceTo: [],
          relationshipName: null,
          type: IntegrationFieldTypeEnum.url,
        },
        {
          array: false,
          caseSensitive: false,
          custom: false,
          defaultValue: null,
          functionality: {
            createable: false,
            updateable: false,
          },
          label: "Edit URL",
          maxLength: 0,
          name: "uiEditUrl",
          nameField: false,
          picklistValues: [],
          readonly: true,
          referenceTo: [],
          relationshipName: null,
          type: IntegrationFieldTypeEnum.url,
        },
      ],
    },
  },
  STRIPE: {
    Address: {
      name: "Address",
      label: "Address",
      labelPlural: "Addresses",
      fields: [
        { name: "city", label: "City" },
        { name: "country", label: "Country" },
        { name: "line1", label: "Line 1" },
        { name: "line2", label: "Line 2" },
        { name: "postalCode", label: "Postal Code" },
        { name: "state", label: "State" },
      ],
    },
    Customer: {
      name: "Customer",
      label: "Customer",
      labelPlural: "Customers",
      fields: [
        {
          name: "address",
          relationshipName: "address",
          referenceTo: ["Address"],
          type: IntegrationFieldTypeEnum.reference,
          label: "Address",
        },
        { name: "description", label: "Description" },
        { name: "email", label: "Email" },
        { name: "id", label: "Customer ID" },
        { name: "name", label: "Name" },
      ],
    },
    Plan: {
      name: "Plan",
      label: "Plan",
      labelPlural: "Plans",
      fields: [
        { name: "amount", label: "Amount" },
        { name: "currency", label: "Currency" },
        { name: "id", label: "Plan ID" },
        { name: "interval", label: "Interval" },
        { name: "nickname", label: "Nickname" },
      ],
    },
    Subscription: {
      name: "Subscription",
      label: "Subscription",
      labelPlural: "Subscriptions",
      fields: [
        { name: "created", label: "Created" },
        { name: "collectionMethod", label: "Collection Method" },
        { name: "currentPeriodEnd", label: "Current Period End" },
        { name: "currentPeriodStart", label: "Current Period Start" },
        { name: "id", label: "Subscription ID" },
        {
          name: "plan",
          relationshipName: "plan",
          referenceTo: ["Plan"],
          type: IntegrationFieldTypeEnum.reference,
          label: "Plan",
        },
        { name: "quantity", label: "Quantity" },
        { name: "status", label: "Status" },
        { name: "startDate", label: "Start Date" },
      ],
    },
    PreviousSubscription: {
      name: "PreviousSubscription",
      label: "PreviousSubscription",
      labelPlural: "PreviousSubscriptions",
      fields: [
        { name: "currentPeriodEnd", label: "Current Period End" },
        { name: "currentPeriodStart", label: "Current Period Start" },
        { name: "id", label: "Previous Subscription ID" },
        { name: "latestInvoice", label: "Latest Invoice" },
        {
          name: "plan",
          relationshipName: "plan",
          referenceTo: ["Plan"],
          type: IntegrationFieldTypeEnum.reference,
          label: "Plan",
        },
      ],
    },
  },
};
