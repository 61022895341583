<template>
  <div class="wizard-xl:hidden z-50">
    <div class="fixed inset-0 flex z-40">
      <button @click="toggleNav">
        <div class="fixed inset-0" aria-hidden="true">
          <div class="absolute inset-0" />
        </div>
      </button>
      <div class="relative flex-1 flex flex-col max-w-[15rem] w-full pt-5 pb-4 bg-mm-colors-sidebarBackground shadow-lg">
        <div class="absolute top-0 right-0 -mr-12 pt-2">
          <button ref="sidebar-mobile" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="toggleNav">
            <span class="sr-only">Close sidebar</span>
          </button>
        </div>
        <div class="flex-shrink-0 flex items-center justify-between px-6">
          <img class="h-6 w-[80%]" src="../../assets/images/full-logo-dark.png" alt="Momentum Logo">
          <NPopover trigger="hover">
            <template #trigger>
              <CButton type="button" name="textPlainButton" class="ml-6" @click="toggleNav">
                <MIcon icon="IconPanelLeftContract" class="w-6 h-6" />
              </CButton>
            </template>
            <span>Collapse Sidebar</span>
          </NPopover>
        </div>
        <div class="mt-5 flex-1 flex-col h-0 overflow-y-auto">
          <slot />
        </div>
        <div class="space-y-1 pl-4 py-5 bg-mm-colors-sidebarBackground border-t border-gray-200">
          <div class="ml-2">
            <Profile />
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 w-12" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { NPopover } from "naive-ui";
import { CButton } from "../library";
import Profile from "./ProfileDropdown.vue";
import MIcon from "../../components/reusable-components/MIcon.vue";

export default defineComponent({
  components: {
    Profile,
    CButton,
    NPopover,
    MIcon,
  },
  setup() {
    const store: any = useStore();

    function toggleNav() {
      store.dispatch("toggleSideBar", !store.state.showSideBar);
    }
    return {
      store,
      toggleNav,
    };
  },
});
</script>

<style scoped>
.noStyle {
  border: none;
  outline: none;
}
</style>
