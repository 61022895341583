<template>
  <div class="absolute bottom-[70px] left-[20px] w-[400px] z-50 max-h-[500px] overflow-y-auto">
    <div v-show="getNotifications.length">
      <div v-for="notification in getNotifications" :key="notification.id">
        <AppNotification :notification="notification" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import AppNotification from "./AppNotification.vue";

export default defineComponent({
  components: {
    AppNotification,
  },
  setup() {
    const store: any = useStore();

    const getNotifications = computed(() => store.getters["notifications/getNotifications"]);

    return {
      getNotifications,
    };
  },
});
</script>
