import { cloneDeep } from "lodash";
export const deal_assist = {
  state: () => ({
    workflow: null,
    opFields: null,
  }),

  mutations: {
    setWorkflow(state, payload) {
      const workflow = cloneDeep(payload);
      state.workflow = workflow;
    },
    setOpFields(state, fields) {
      state.opFields = fields;
    },
  },
  actions: {
    //form updates
    setWorkflow({ commit }, data) {
      commit("setWorkflow", data);
    },
    setOpFields({ commit }, fields) {
      commit("setOpFields", fields);
    },
  },
};
