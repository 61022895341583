<template>
  <div>
    <transition name="fade">
      <section v-show="notification" class="w-full max-w-md">
        <button type="button" class="w-full max-w-md" :class="{'cursor-default': notification.severity !== 'error'}" @click="showErrorMessage">
          <div
            v-if="state.show"
            class="relative flex flex-wrap justify-between items-center rounded-md bg-white shadow-md sm:rounded-md p-4 mt-2 w-full"
            :class="{
              'border-2 border-green-400': notification.severity === 'info',
              'border-2 border-yellow-400': notification.severity === 'warning',
              'border-2 border-red-400': notification.severity === 'error',
            }"
          > 
            <IconEye v-show="notification.severity === 'error'" class="absolute top-1 right-6 h-4 w-4 text-gray-600" />

            <button type="button" class="absolute top-1 right-1" @click="clearNotification">
              <IconXClose class="h-4 w-4 text-gray-600" />
            </button>
      
            <div class="flex items-center">
              <div class="flex-shrink-0 items-center">
                <!-- Heroicon name: solid/exclamation -->
                <div v-show="notification.severity === 'warning'" class="h-8 w-8 text-yellow-400">
                  <IconWarning />
                </div>
                <div v-show="notification.severity === 'info'" class="h-8 w-8 text-green-400">
                  <IconSuccess />
                </div>
                <div v-show="notification.severity === 'error'" class="h-8 w-8 text-red-400">
                  <IconError />
                </div>
              </div>
              <div class="ml-3">
                <p class="ml-3 text-left text-xs" v-text="notification.message" />
              </div>
            </div>
          </div>
        </button>

        <NModal v-model:show="state.showErrorMessage">
          <NCard
            style="width: 600px"
            :bordered="false"
            size="huge"
            role="dialog"
            aria-modal="true"
          >
            <NAlert title="Error Message" type="error">
              <p v-text="notification.message" />
            </NAlert>
          </NCard>
        </NModal>
      </section>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted, PropType, watch } from "vue";
import { Store, useStore } from "vuex";
import { NModal, NCard, NAlert } from "naive-ui";
import { formatDateTZ } from "../../utils/common-js";
import IconError from "../icons/IconError.vue";
import IconSuccess from "../icons/IconSuccess.vue";
import IconWarning from "../icons/IconWarning.vue";
import IconXClose from "../icons/IconXClose.vue";
import IconEye from "../icons/IconEye.vue";
import { TNotification } from "../../types/notification";

export default defineComponent({
  components: {
    NAlert,
    NModal,
    NCard,
    IconError,
    IconSuccess,
    IconWarning,
    IconXClose,
    IconEye,
  },
  props: {
    notification: {
      type: Object as PropType<TNotification>,
      required: true,
    },
  },
  setup(props) {
    interface State {
      showErrorMessage: boolean;
      show: boolean;
    }
    const store: Store<any> = useStore();
    const state: State = reactive({
      showErrorMessage: false,
      show: true,
    });

    onMounted(() => {
      setTimeout(() => {
        clearNotification();
      }, 8000); //8 seconds
    });

    watch(
      () => state.showErrorMessage,
      (newValue) => {
        if (!newValue) {
          clearNotification();
        }
      }
    );

    async function clearNotification(event?: Event): Promise<void> {
      if (event) {
        event.stopPropagation();
      }
      if (state.showErrorMessage) return null;

      state.show = false;

      await store.dispatch("notifications/removeNotification", props.notification.id);
    }

    function showErrorMessage(): void {
      if (props.notification.severity === "error") {
        state.showErrorMessage = true;
      }
    }

    return {
      state,
      showErrorMessage,
      clearNotification,
      formatDateTZ,
    };
  },
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
